
.tutorialBox {
  padding: 15px;

  .loader {
    margin: auto;
    margin-top: 50px;
    text-align: center;
  }

  h4 {
    text-align: center;
    margin-top: 50px;
  }

  .tutorialContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}