.rate {
  width: 175px;
  display: flex;
  flex-direction: row;

  .star {
    background: url('../_assets/rater/emptyStar.svg');
    height: 25px;
    width: 25px;
    background-size: 25px 25px;
  }

  .starActive {
    background: url('../_assets/rater/filledStar.svg');
  }
}