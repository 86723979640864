
.fileUploader {
  :global(.btn-primary) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: 0;
  }
  .err {
    color: red;
  }
  .append {
    transition: width 0.3s;
    width: 37px;
    overflow: hidden;
  }
  .appendDisabled {
    width: 0px;
  }

}