.environnement{width:150px; display: block;}
.langues{width:150px; display: block;margin-left:20px}

.navbar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navbar-content-left {
  display: flex;
  margin-left: 8px;
}
.navbar-content-left .nav {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 8px;
  margin-right: 8px;
}

.navbar-content-center {
  display: flex !important;
}
.navbar-content-center .nav {
  margin: 8px;
}
.navbar-content-center .nav .select {
  width: 120px;
}
.navbar-content-center .nav .select-desc{
  margin-right: 8px;
  position: relative;
    top: 10px;
}

.navbar-content-right {
  display: flex;
  margin-right: 8px;
}
.navbar-content-right .nav {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 8px;
  margin-right: 8px;
}
.navbar-content-right .nav .btn {
  position: relative;
    top: -5px;
    border-radius: 100px;
    padding-right: 6px;
    padding-left: 10px;
}